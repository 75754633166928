import { SharedModelState } from '../shared.model.state';

export interface PermissionState extends SharedModelState<PermissionModel> {}

export interface PermissionList {
  [moduleId: string]: PermissionModel[];
}

export interface PermissionModel {
  id?: string;
  key: string;
  name: string;
  description: string;
  module_id: string;
}

// fake permissions because users can always see this but they make it easier to check
type DefaultPermission = 'View own roster' | 'View own public uploads' | 'View own availability';

export type PermissionOption = Permission | Permission[] | 'any';

export type Permission =
  | DefaultPermission
  | 'Create news item'
  | 'Edit news item'
  | 'Edit own news item'
  | 'Delete news item'
  | 'Delete own news item'
  | 'View comment'
  | 'Create comment'
  | 'Edit comment'
  | 'Edit own comment'
  | 'Delete comment'
  | 'Delete own comment'
  | 'View all rosters'
  | 'Create roster'
  | 'Create own roster'
  | 'Edit roster'
  | 'Edit own roster'
  | 'Delete roster'
  | 'Delete own roster'
  | 'Create teams'
  | 'Edit teams'
  | 'Delete teams'
  | 'Create shifts'
  | 'Edit shifts'
  | 'Delete shifts'
  | 'View required shifts'
  | 'Create required shift'
  | 'Edit required shift'
  | 'Delete required shift'
  | 'View all timesheets'
  | 'View own timesheet'
  | 'Create timesheets'
  | 'Create own timesheet'
  | 'Edit timesheets'
  | 'Edit own timesheet'
  | 'Approve timesheets'
  | 'Close timesheets'
  | 'View log'
  | 'Edit log'
  | 'View absentee'
  | 'Create absentee'
  | 'Edit absentee'
  | 'Delete absentee'
  | 'View all users'
  | 'Create users'
  | 'Edit users'
  | 'Activate users'
  | 'Delete users'
  | 'Edit contracts'
  | 'View own absentee'
  | 'Edit user permissions'
  | 'Manage account'
  | 'View reports'
  | 'View salary'
  | 'View own salary'
  | 'View inactive users'
  | 'Approve absentee'
  | 'View vacation hours'
  | 'View plus min hours'
  | 'View own plus min hours'
  | 'Edit vacation hours'
  | 'View own time off balances'
  | 'View time off balances'
  | 'Adjust time off balances'
  | 'Edit plus min hours'
  | 'Write team notes'
  | 'View team notes'
  | 'Create event'
  | 'Edit event'
  | 'Delete event'
  | 'Request exchange'
  | 'Approve exchange'
  | 'Approve own exchange'
  | 'View contracts'
  | 'Create contracts'
  | 'Delete contracts'
  | 'View availability'
  | 'Edit availability'
  | 'Edit own availability'
  | 'View user notes'
  | 'Edit user notes'
  | 'Delete user notes'
  | 'Clock time'
  | 'Clock own time'
  | 'Clock own time mobile'
  | 'Edit clocked time'
  | 'Edit own clocked time'
  | 'Edit budget'
  | 'View budget'
  | 'Edit forecast'
  | 'View forecast'
  | 'Edit own picture'
  | 'Edit own profile'
  | 'Add own user uploads'
  | 'Edit own user uploads'
  | 'Delete own user uploads'
  | 'Add user uploads'
  | 'View user uploads'
  | 'Edit user uploads'
  | 'Delete user uploads'
  | 'Approve own absentee'
  | 'View own profile'
  | 'Edit own absentee'
  | 'Delete own absentee'
  | 'Open timesheets'
  | 'Clock in own time anywhere'
  | 'Clock out own time anywhere'
  | 'Disable MFA for user'
  | 'Create own absentee'
  | 'View user details'
  | 'Manage API tokens'
  | 'Manage payroll apps'
  | 'Manage non payroll apps'
  | 'Edit own notification settings'
  | 'Edit user teams'
  | 'View own clock time';

export enum PermissionType {
  MANAGE_PAYROLL_APPS = 'Manage payroll apps',
  MANAGE_API_TOKENS = 'Manage API tokens',
  MANAGE_NON_PAYROLL_APPS = 'Manage non payroll apps',
  MANAGE_ACCOUNT = 'Manage account',
  VIEW_OWN_TIME_OFF_BALANCES = 'View own time off balances',
  VIEW_TIME_OFF_BALANCES = 'View time off balances',
  WRITE_TEAM_NOTES = 'Write team notes',
}
